export function timeline() {
  document.addEventListener("DOMContentLoaded", function () {
    const timelineItems = document.querySelectorAll(".timeline-item");
    const timelineTitles = document.querySelectorAll(".timeline-title");

    // Thêm sự kiện click cho các tiêu đề (timeline titles)
    timelineTitles.forEach((title) => {
      title.addEventListener("click", () => {
        // Xóa class "title-active" khỏi tất cả tiêu đề
        timelineTitles.forEach((t) => t.classList.remove("title-active"));

        // Thêm class "title-active" vào tiêu đề được click
        title.classList.add("title-active");
      });
    });

    // Thêm sự kiện click cho từng timeline-item
    timelineItems.forEach((item, index) => {
      item.addEventListener("click", () => {
        // Xóa class "show-content" khỏi tất cả các item
        timelineItems.forEach((i) => i.classList.remove("show-content"));

        // Thêm class "show-content" vào item được click
        item.classList.add("show-content");

        // Cập nhật dot bằng cách thao tác với class của chính item
        timelineItems.forEach((i) => i.classList.remove("active-dot"));
        item.classList.add("active-dot");
      });
    });
  });
}

// Khởi tạo timeline khi DOM đã sẵn sàng
timeline();
