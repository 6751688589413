export function showPopup() {
  document.addEventListener("DOMContentLoaded", function () {
    const popup = document.querySelector(".popup");
    const popupContent = document.querySelector(".popup-content");
    const popupLogin = document.querySelector(".popup-login");

    const popupContentLogin = document.querySelector(".popup-login-content");
    const loginBtn = document.querySelector(".login-btn");
    loginBtn.addEventListener("click", function () {
      popupContentLogin.classList.add("show");
      popupLogin.classList.add("show");
    });

    // Hiển thị .popup sau 2 giây
    setTimeout(function () {
      popup.classList.add("show");
    }, 1500); // 2 giây sau khi trang đã tải

    // Hiển thị .popup-content sau 2.5 giây (0.5s sau .popup)
    setTimeout(function () {
      popupContent.classList.add("show");
    }, 1600); // 2.5 giây sau khi trang đã tải

    function closePopup() {
      const closeBtn = document.querySelector(".popup-close");
      closeBtn.addEventListener("click", function () {
        popup.classList.remove("show");
      });
    }

    function closePopupLogin() {
      const closeBtnLogin = document.querySelector(".popup-login-close");
      closeBtnLogin.addEventListener("click", function () {
        popupContentLogin.classList.remove("show");
        popupLogin.classList.remove("show");
      });
    }

    closePopup();
    closePopupLogin();
  });
}
showPopup();
