export function loginMember() {
  document.addEventListener("DOMContentLoaded", function () {
    const courseLogin = document.querySelector(".course");
    const btnLogin = document.querySelector(".header-button-login");
    const btnLoginLearn = document.querySelector(".login-btn");
    const popopLogin = document.querySelector(".popup-login");
    const closeLoginBtn = document.querySelector(".popup-login-close");
    const popupLoginContent = document.querySelector(".popup-login-content");

    if (btnLogin && courseLogin) {
      btnLogin.addEventListener("click", function () {
        courseLogin.classList.toggle("show");
      });
    }

    if (btnLoginLearn && popopLogin && popupLoginContent) {
      btnLoginLearn.addEventListener("click", function () {
        popopLogin.classList.add("show");
        popupLoginContent.classList.add("show");
      });
    }

    if (closeLoginBtn && popopLogin && popupLoginContent) {
      closeLoginBtn.addEventListener("click", function () {
        popopLogin.classList.remove("show");
        popupLoginContent.classList.remove("show");
      });
    }
  });
}

loginMember();
