export function showMenu() {
  document.addEventListener("DOMContentLoaded", function () {
    const toggle = document.querySelector(".header-toggle"); // Lấy nút toggle
    const menu = document.querySelector(".header-menu-mobile"); // Lấy menu

    if (toggle && menu) {
      toggle.addEventListener("click", function () {
        // Toggle class "is-show" để mở/đóng menu
        menu.classList.toggle("is-show");

        // Kiểm tra trạng thái menu và thay đổi icon
        if (menu.classList.contains("is-show")) {
          toggle.querySelector("i").classList.remove("fa-bars"); // Loại bỏ icon fa-bars
          toggle.querySelector("i").classList.add("fa-times"); // Thêm icon fa-times
        } else {
          toggle.querySelector("i").classList.remove("fa-times"); // Loại bỏ icon fa-times
          toggle.querySelector("i").classList.add("fa-bars"); // Thêm icon fa-bars
        }
      });
    } else {
      console.error("Không tìm thấy phần tử .header-toggle hoặc .header-menu-mobile");
    }
  });
}

showMenu();
