import Swiper from "swiper";
import { Autoplay, EffectCoverflow, Grid, Navigation, Pagination } from "swiper/modules";
/**
 * @param swiperInit
 */
export function swiperInit() {
  // swiperStaff();
  // swiperProductSimilar();
  swiperBanner();
  swiperBannerMobile();
  swiperExperience();
  swiperGlobalTalent();
  swiperListen();
  swiperClub();
  swiperSecret();
  swiperLearnSpace();
  swiperRoom();
  swiperRoomGroup();
  swiperCafeteri();
  swiperDesc();
  swiperEvent();
  swiperDestinationSpace();
  swiperTour();
  swiperProgramSimilar();
  swiperBlog();
  swiperPlan();
  swiperTimeline();
  swiperExperienceMobile();
  swiperExperienceGlobalMobile();
}

// function swiperStaff() {
//   const swiper = new Swiper(".swiper-staff", {
//     spaceBetween: 20, // Khoảng cách giữa các slide
//     slidesPerView: 2,
//     modules: [Autoplay, Navigation],
//     autoplay: {
//       delay: 3500,
//       disableOnInteraction: false,
//     },
//     speed: 2000,
//     navigation: {
//       nextEl: ".custom-next",
//       prevEl: ".custom-prev",
//     },
//     breakpoints: {
//       640: {
//         slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
//         spaceBetween: 20,
//       },
//       768: {
//         slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
//         spaceBetween: 30,
//       },
//       1024: {
//         slidesPerView: 3, // Số slide trên mỗi hàng ở kích thước màn hình lớn
//         spaceBetween: 40,
//       },
//     },
//   });
// }

function swiperBanner() {
  const swiper = new Swiper(".banner-swiper ", {
    slidesPerView: 1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },
  });
}

function swiperBannerMobile() {
  const swiper = new Swiper(".banner-mobile-swiper", {
    slidesPerView: 1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    speed: 1500,
  });
}

function swiperListen() {
  const swiper = new Swiper(".listen-swiper", {
    spaceBetween: 40, // Khoảng cách giữa các slide
    slidesPerView: 1,
    modules: [Autoplay, Navigation, Grid],

    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".listen-next",
      prevEl: ".listen-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
        grid: {
          rows: 2, // Điều này sẽ tạo ra 2 hàng
          fill: "row",
        },
      },
    },
  });
}

function swiperExperience() {
  const swiper = new Swiper(".experience-swiper", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1.2,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperClub() {
  const swiper = new Swiper(".club-swiper", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1.1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".club-next",
      prevEl: ".club-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperProgramSimilar() {
  const swiper = new Swiper(".program-similar", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".program-next",
      prevEl: ".program-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperTour() {
  const swiper = new Swiper(".tour-swiper", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 2,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".club-next",
      prevEl: ".club-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperTimeline() {
  const swiper = new Swiper(".timeline-swiper", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1,
    modules: [Autoplay, Navigation, Pagination],
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: ".swiper-pagination-timeline",
      clickable: true,
    },
    speed: 1000,
  });
}

function swiperSecret() {
  const swiper = new Swiper(".secret-swiper", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".secret-next",
      prevEl: ".secret-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 4, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperDestinationSpace() {
  const swiper = new Swiper(".destination-swiper", {
    modules: [Autoplay, Navigation, EffectCoverflow], // Đảm bảo đã import đúng các module
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 680, // Khoảng cách giữa các slide
    effect: "coverflow",
    centeredSlides: true,
    grabCursor: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 90,
      depth: 800,
      modifier: 1,
      slideShadows: false,
    },

    loop: true,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".genz-next",
      prevEl: ".genz-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },

    // Autoplay (tự động chuyển slide)
    // autoplay: {
    //   delay: 2500, // Tự động chuyển slide sau 2.5s
    //   disableOnInteraction: false, // Tiếp tục autoplay ngay cả khi người dùng tương tác
    // },
  });
}

function swiperLearnSpace() {
  const swiper = new Swiper(".learn-swiper", {
    modules: [Autoplay, Navigation, EffectCoverflow], // Đảm bảo đã import đúng các module
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 400, // Khoảng cách giữa các slide
    effect: "coverflow",
    centeredSlides: true,
    grabCursor: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 800,
      modifier: 1,
      slideShadows: false,
    },

    loop: true,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".genz-next",
      prevEl: ".genz-prev",
    },

    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: "auto", // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },

    // Autoplay (tự động chuyển slide)
    // autoplay: {
    //   delay: 2500, // Tự động chuyển slide sau 2.5s
    //   disableOnInteraction: false, // Tiếp tục autoplay ngay cả khi người dùng tương tác
    // },
  });
}

function swiperRoom() {
  const swiper = new Swiper(".room-swiper", {
    modules: [Autoplay, Navigation], // Đảm bảo đã import đúng các module
    slidesPerView: 1,

    loop: true,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".room-next",
      prevEl: ".room-prev",
    },
  });
}

function swiperRoomGroup() {
  const swiper = new Swiper(".room-group-swiper", {
    modules: [Autoplay, Navigation], // Đảm bảo đã import đúng các module
    slidesPerView: 1,

    loop: true,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".room-group-next",
      prevEl: ".room-group-prev",
    },
  });
}

function swiperCafeteri() {
  const swiper = new Swiper(".cafeteria-swiper", {
    modules: [Autoplay, Navigation], // Đảm bảo đã import đúng các module
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1400,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".cafeteria-next",
      prevEl: ".cafeteria-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperDesc() {
  const swiper = new Swiper(".desc-swiper", {
    modules: [Autoplay, Navigation], // Đảm bảo đã import đúng các module
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1200,
    navigation: {
      nextEl: ".desc-next",
      prevEl: ".desc-prev",
    },
  });
}

function swiperEvent() {
  const swiper = new Swiper(".event-swiper", {
    modules: [Autoplay, Navigation], // Đảm bảo đã import đúng các module
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    // Pagination (nút chấm điều hướng)
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".event-next",
      prevEl: ".event-prev",
    },
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    speed: 1000,
  });
}

function swiperPlan() {
  const swiper = new Swiper(".plan-swiper", {
    modules: [Autoplay, Pagination], // Đảm bảo đã import đúng các module
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
  });
}

function swiperGlobalTalent() {
  const swiper = new Swiper(".global-talent-swiper ", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 2,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".next-talent",
      prevEl: ".prev-talent",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 4, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

function swiperBlog() {
  const swiper = new Swiper(".blog-swiper ", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 2,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".blog-next",
      prevEl: ".blog-prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 1, // Số slide trên mỗi hàng ở kích thước màn hình nhỏ
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2, // Số slide trên mỗi hàng ở kích thước màn hình trung bình
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3, // Số slide trên mỗi hàng ở kích thước màn hình lớn
        spaceBetween: 40,
      },
    },
  });
}

// Mobile
function swiperExperienceMobile() {
  const swiper = new Swiper(".experience-swiper-slide ", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,
    navigation: {
      nextEl: ".experience-btn-next",
      prevEl: ".experience-btn-prev",
    },
  });
}

function swiperExperienceGlobalMobile() {
  const swiper = new Swiper(".expericence-global-talent-swiper", {
    spaceBetween: 20, // Khoảng cách giữa các slide
    slidesPerView: 1,
    modules: [Autoplay, Navigation],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1000,

    navigation: {
      nextEl: ".experience-global-btn-next",
      prevEl: ".experience-global-btn-prev",
    },
  });
}
