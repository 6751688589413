export function imageSiblingWithAccordion() {
  document.addEventListener("DOMContentLoaded", function () {
    const questionItems = document.querySelectorAll(".home-program-item"); // Các câu hỏi (accordion)
    const images = document.querySelectorAll(".home-program-image"); // Các hình ảnh
    const backgrounds = document.querySelectorAll(".home-program-image .bg"); // Các nền của hình ảnh
    const titles = document.querySelectorAll(".home-program-item-title"); // Các tiêu đề bên trái
    const toggles = document.querySelectorAll(".home-program-down"); // Các nút toggle của accordion

    // Lắng nghe sự kiện click trên các tiêu đề (accordion)
    titles.forEach((title) => {
      title.addEventListener("click", (event) => {
        event.preventDefault(); // Ngừng hành động mặc định của thẻ <a>

        const targetId = title.getAttribute("data-target"); // Lấy ID của chương trình tương ứng
        const targetImage = document.getElementById(targetId); // Tìm hình ảnh tương ứng

        if (targetImage) {
          // Loại bỏ lớp 'show_img', 'image-active' khỏi tất cả hình ảnh và nền
          remove_class(images);
          remove_class(backgrounds);

          // Thêm lớp 'show_img' và 'image-active' vào hình ảnh đã nhấp
          targetImage.classList.add("show_img");
          targetImage.classList.add("image-active");

          // Thêm lớp 'bg-show' vào phần nền tương ứng
          const bg = targetImage.querySelector(".bg");
          if (bg) {
            bg.classList.add("bg-show");
          }
        }

        // Mở/đóng accordion khi click vào tiêu đề
        toggleAccordion(title);
      });
    });

    // Lắng nghe sự kiện click trên các nút toggle (home-program-down)
    toggles.forEach((toggle) => {
      toggle.addEventListener("click", (event) => {
        event.preventDefault(); // Ngừng hành động mặc định của thẻ <a>

        const targetTitle = toggle
          .closest(".home-program-item")
          .querySelector(".home-program-item-title");

        if (targetTitle) {
          // Lặp lại hành động của tiêu đề khi click vào nút toggle
          targetTitle.click();
        }
      });
    });

    // Lắng nghe sự kiện click trên các hình ảnh (cũng mở hình ảnh tương ứng)
    images.forEach((img) => {
      img.addEventListener("click", (event) => {
        event.preventDefault(); // Ngừng hành động mặc định của thẻ <a>

        // Lấy ID tương ứng của hình ảnh đã click
        const targetId = img.getAttribute("data-target"); // Lấy ID của chương trình tương ứng
        const targetTitle = document.querySelector(`[data-target="${targetId}"]`); // Tìm tiêu đề tương ứng

        if (targetTitle) {
          // Mở/đóng accordion khi click vào hình ảnh
          toggleAccordion(targetTitle);
        }

        // Loại bỏ lớp 'show_img', 'image-active' khỏi tất cả hình ảnh và nền
        remove_class(images);
        remove_class(backgrounds);

        // Thêm lớp 'show_img' và 'image-active' vào hình ảnh đã nhấp
        img.classList.add("show_img");
        img.classList.add("image-active");

        // Thêm lớp 'bg-show' vào phần nền tương ứng
        const bg = img.querySelector(".bg");
        if (bg) {
          bg.classList.add("bg-show");
        }
      });
    });

    // Hàm toggle accordion
    function toggleAccordion(title) {
      const item = title.closest(".home-program-item"); // Tìm phần tử cha
      const icon = item.querySelector(".home-program-down i"); // Icon để click
      const answer = item.querySelector(".home-program-item-content"); // Nội dung sẽ mở/đóng

      // Kiểm tra nếu các phần tử tồn tại
      if (!icon || !answer) {
        return; // Nếu không tìm thấy phần tử nào, bỏ qua mục này
      }

      const isActive = item.classList.contains("show");

      // Đóng tất cả các câu hỏi khác
      questionItems.forEach((otherItem) => {
        if (otherItem !== item) {
          const otherAnswer = otherItem.querySelector(".home-program-item-content");
          const otherIcon = otherItem.querySelector(".home-program-down i");

          // Đảm bảo các phần tử tồn tại trước khi thao tác
          if (otherAnswer && otherIcon) {
            otherItem.classList.remove("show");
            otherAnswer.style.maxHeight = "0"; // Đóng câu hỏi khác
            otherIcon.classList.replace("fa-chevron-up", "fa-chevron-down"); // Đổi icon
          }
        }
      });

      // Toggle lớp 'show' cho câu hỏi hiện tại
      item.classList.toggle("show");

      // Cập nhật chiều cao và icon
      if (item.classList.contains("show")) {
        answer.style.maxHeight = answer.scrollHeight + "px"; // Mở
        icon.classList.replace("fa-chevron-down", "fa-chevron-up"); // Đổi icon
      } else {
        answer.style.maxHeight = "0"; // Đóng
        icon.classList.replace("fa-chevron-up", "fa-chevron-down"); // Đổi icon
      }
    }

    // Hàm loại bỏ lớp 'show_img', 'image-active', 'bg-show' khỏi tất cả các phần tử
    function remove_class(elements) {
      elements.forEach((element) => {
        element.classList.remove("show_img");
        element.classList.remove("image-active");

        // Loại bỏ lớp 'bg-show' khỏi các phần nền
        const bg = element.querySelector(".bg");
        if (bg) {
          bg.classList.remove("bg-show");
        }
      });
    }
  });
}

// Gọi hàm imageSiblingWithAccordion khi DOM đã sẵn sàng
imageSiblingWithAccordion();
