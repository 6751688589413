export function tabItem() {
  document.addEventListener("DOMContentLoaded", function () {
    const $ = document.querySelector.bind(document);
    const $$ = document.querySelectorAll.bind(document);

    const tabs = $$(".tab-item");
    const panes = $$(".tab-pane");
    const line = $(".tabs .line");

    // Kiểm tra nếu phần tử .line tồn tại trước khi thao tác với nó
    if (line && tabs.length > 0 && panes.length > 0) {
      const tabActive = $(".tab-item.show");

      // Nếu tabActive tồn tại, thiết lập vị trí và kích thước cho line
      if (tabActive) {
        line.style.left = tabActive.offsetLeft + "px";
        line.style.width = tabActive.offsetWidth + "px";
      }

      tabs.forEach((tab, index) => {
        const pane = panes[index];
        tab.onclick = function () {
          // Ẩn tất cả các phần tử đang được hiển thị
          $(".tab-item.show")?.classList.remove("show");
          $(".tab-pane.show")?.classList.remove("show");

          // Hiển thị tab và pane tương ứng
          this.classList.add("show");
          pane.classList.add("show");

          // Cập nhật lại vị trí và kích thước của line
          line.style.left = this.offsetLeft + "px";
          line.style.width = this.offsetWidth + "px";
        };
      });
    } else {
      console.error("Không tìm thấy các phần tử cần thiết.");
    }
  });
}

tabItem();
