import AOS from "aos";
import lozad from "lozad";
import {
  setBackgroundElement,
  detectCloseElement,
  buttonToTop,
  clickScrollToDiv,
  appendCaptchaASP,
} from "./helper";
import { header } from "./header";
import { swiperInit } from "./swiper";
import { showPopup } from "./popup";
import { showMenu } from "./menuMobile";
import { questionItem } from "./faq";
import { footer } from "./footer";
import { questionGlobalItem } from "./faq";
import { showTalentGlobalEn } from "./programTalent";
import { loginMember } from "./login";
import { imageSiblingWithAccordion } from "./imageSibling";
import { tabItem } from "./tabItem";
import { timeline } from "./timeline";
$(document).ready(function () {
  setBackgroundElement();
  showPopup();
  showTalentGlobalEn();
  questionGlobalItem();
  showMenu();
  imageSiblingWithAccordion();
  tabItem();
  timeline();
  footer();
  loginMember();
  header.init();
  swiperInit();
});

/*==================== Aos Init ====================*/
document.addEventListener("DOMContentLoaded", () => {
  AOS.init({
    disable: () => window.innerWidth < 768,
    duration: 700,
    once: true,
  });
});
AOS.refresh();
/*==================== Lazyload JS ====================*/
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();
