// Hàm để xử lý accordion
export function footer() {
  document.addEventListener("DOMContentLoaded", function () {
    const questionItems = document.querySelectorAll(".footer-col-mobile");

    questionItems.forEach((item) => {
      const icon = item.querySelector(".icon i"); // Icon để click
      const answer = item.querySelector(".footer-menu-mobile"); // Nội dung sẽ mở/đóng

      // Kiểm tra nếu các phần tử tồn tại
      if (!icon || !answer) {
        return; // Nếu không tìm thấy phần tử nào, bỏ qua mục này
      }

      // Lắng nghe sự kiện click trên biểu tượng
      icon.addEventListener("click", () => {
        const isActive = item.classList.contains("show");

        // Đóng tất cả các câu hỏi khác
        questionItems.forEach((otherItem) => {
          if (otherItem !== item) {
            const otherAnswer = otherItem.querySelector(".footer-col-mobile");
            const otherIcon = otherItem.querySelector(".icon i");

            // Đảm bảo các phần tử tồn tại trước khi thao tác
            if (otherAnswer && otherIcon) {
              otherItem.classList.remove("show");
              otherAnswer.style.maxHeight = "0"; // Đóng câu hỏi khác
              otherIcon.classList.replace("fa-chevron-up", "fa-chevron-down"); // Đổi icon
            }
          }
        });

        // Toggle lớp 'show' cho câu hỏi hiện tại
        item.classList.toggle("show");

        // Cập nhật chiều cao và icon
        if (item.classList.contains("show")) {
          answer.style.maxHeight = answer.scrollHeight + "px"; // Mở
          icon.classList.replace("fa-chevron-down", "fa-chevron-up"); // Đổi icon
        } else {
          answer.style.maxHeight = "0"; // Đóng
          icon.classList.replace("fa-chevron-up", "fa-chevron-down"); // Đổi icon
        }
      });
    });
  });
}
footer();
