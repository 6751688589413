export function showTalentGlobalEn() {
  document.addEventListener("DOMContentLoaded", function () {
    const globalTalent = document.querySelector(".global-talent");
    const globalTalentList = document.querySelector(".global-list");
    const globalTalentContent = document.querySelector(".global-talent-content");

    const experienceLink = document.querySelector(".experience-link");
    const experienceList = document.querySelector(".experience-list");
    const experienceContent = document.querySelector(".experience-content");

    const newsLink = document.querySelector(".news-link");
    const newsList = document.querySelector(".news-list");
    const newsContent = document.querySelector(".news-content");

    const activeClass = "show";

    // Kiểm tra sự tồn tại của các phần tử trước khi thêm sự kiện
    if (globalTalent && globalTalentList && globalTalentContent) {
      globalTalent.addEventListener("click", function () {
        globalTalentList.classList.add("active");
        globalTalentContent.classList.add(activeClass);
      });
    }

    if (experienceLink && experienceList && experienceContent) {
      experienceLink.addEventListener("click", function () {
        experienceList.classList.add("active");
        experienceContent.classList.add(activeClass);
      });
    }

    if (newsLink && newsList && newsContent) {
      newsLink.addEventListener("click", function () {
        newsList.classList.add("active");
        newsContent.classList.add(activeClass);
      });
    }

    // Lấy đường dẫn đầy đủ hiện tại của trang
    const currentUrl = window.location.href;

    // Lấy tất cả các thẻ li trong menu
    const menuItems = document.querySelectorAll(".header-menu ul li");

    // Duyệt qua tất cả các thẻ li và kiểm tra đường dẫn
    menuItems.forEach((item) => {
      const link = item.querySelector("a");
      if (link) {
        const href = link.getAttribute("href");
        let absoluteHref = href;
        if (href.startsWith("./")) {
          absoluteHref = window.location.origin + href.substring(1);
        } else if (href.startsWith("/dist/")) {
          absoluteHref = window.location.origin + href;
        }

        if (currentUrl === absoluteHref) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      }
    });
  });
}

showTalentGlobalEn();
